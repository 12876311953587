import React, { useState, useEffect } from 'react';
import logo from '../assets/empty_juicebox_logo.png';
// import localBackgroundImg from '../assets/trees_with_sunrise.jpeg';
// import localBackgroundVideo from'./assets/empty_juicebox_background_vid.mp4';
import '../App.css';
import { Button, Container, Typography } from '@mui/material';
import retrieveS3Obj from '../util.js'

const isLocal = process.env.NODE_ENV === 'development';

const styles = {
    backgroundVid: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    button:{
      backgroundColor: '#fe1b55',
    },
    content: {
      position: 'absolute',
      top: '25%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#fff',
      textAlign: 'center',
    },
    logoContainer:{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logo:{
      width: '24em',
      paddingTop:'33vh',
    },
    overlay: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, .9)', /* Adjust the alpha value to control the darkness of the overlay */
    },
  };

function HeroBanner() {

    const [backgroundVideoSrc, setBackgroundVideoSrc] = useState();

    const fetchBackgroundVid = async () => {
    
      // Get background video locally or retrieve from the cloud
      try {
        if(!isLocal){
          const response = await retrieveS3Obj();
          console.log('retrieveS3Obj', response);
          setBackgroundVideoSrc(response);
        } 
        return;
      } catch (error) {
        console.error('Error fetching data:', error);
        return;
      }
    };
    
    useEffect(() => {
      fetchBackgroundVid();
    }, []);
    
    return (

        <Container>
            <video autoPlay muted loop style={styles.backgroundVid}>
                <div style={styles.overlay}></div>
                <source src={backgroundVideoSrc} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div style={styles.content}>
                <div style={styles.logoContainer}>
                    <img src={logo}  alt="logo" style={styles.logo}/>        
                </div>
                <Typography style={{marginBottom: '16px'}} color="inherit" align="center" variant="h3" marked="center">
                    Experience The Juice
                </Typography>
                <Button variant="contained" style={styles.button}>Inquire About Booking</Button> 
            </div>
        </Container>
    );
}
  
  export default HeroBanner;
