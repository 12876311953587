import * as React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import AppCurvyLines from '../assets/appCurvyLines.png';
import SoundCloudLogo from '../assets/soundCloudLogo.png';
import YoutubeLogo from '../assets/youtubeLogo.png';
import InstagramLogo from '../assets/instagramLogo.png';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};


function LinkContainer() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#FFF' }}
    >
      <Container sx={{ mt: 15, mb: 30, display: 'flex', position: 'relative' }}>
        <Box
          component="img"
          src={AppCurvyLines}
          alt="curvy lines"
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Link href="https://soundcloud.com/emptyjuicebox-music" underline="none" color="inherit">
                <Box sx={item}>
                <Box
                    component="img"
                    src={SoundCloudLogo}
                    alt="soundcloud"
                    sx={{ height: 55 }}
                />
                <Typography variant="h4" sx={{ my: 5 }}>
                    Listen on SoundCloud
                </Typography>
                </Box>
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <Link href="https://www.youtube.com/channel/UCIBvLzQh8zHZkZYqKrXh7pQ" underline="none" color="inherit">
                <Box sx={item}>
                <Box
                    component="img"
                    src={YoutubeLogo}
                    alt="youtube"
                    sx={{ height: 55 }}
                />
                <Typography variant="h4" sx={{ my: 5 }}>
                    Watch on YouTube
                </Typography>
                </Box>
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <Link href="https://www.instagram.com/emptyjuiceboxmusic/" underline="none" color="inherit">
                <Box sx={item}>
                <Box
                    component="img"
                    src={InstagramLogo}
                    alt="instagram"
                    sx={{ height: 55 }}
                />
                <Typography variant="h4" sx={{ my: 5 }}>
                    Follow on Instagram
                </Typography>
                </Box>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default LinkContainer;


