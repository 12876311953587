
const AWS = require('aws-sdk');

const retrieveS3Obj = async (event, context) => {
  try {

    AWS.config.update({
      accessKeyId: "AKIA3VOAXJTKOO5OVNI4",
      secretAccessKey: "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"
  }); 

    const params = {
      RoleArn: 'arn:aws:iam::801952451796:role/AmazonS3ReadOnlyAccess',
      RoleSessionName: 'S3AccessSession',
    };

    const sts = new AWS.STS();
    const assumeRoleResponse = await sts.assumeRole(params).promise();

    const s3 = new AWS.S3({
      accessKeyId: assumeRoleResponse.Credentials.AccessKeyId,
      secretAccessKey: assumeRoleResponse.Credentials.SecretAccessKey,
      sessionToken: assumeRoleResponse.Credentials.SessionToken,
      region: 'us-west-1',
    });

    const s3Params = {
      Bucket: 'arn:aws:s3:::amplify-emptyjuicebox-dev-191941-deployment',
      Key: 'assets/empty_juicebox_video.mp4',
    };

    const data = await s3.getObject(s3Params).promise();
    console.log('S3 Object Data:', data);

    return {
      statusCode: 200,
      body: JSON.stringify({
        message: 'Successfully retrieved S3 object',
      }),
    };
  } catch (error) {
    console.error('Error:', error);
    return {
      statusCode: 500,
      body: JSON.stringify({
        message: 'Error retrieving S3 object',
        error: error.message,
      }),
    };
  }
};



module.exports = retrieveS3Obj;