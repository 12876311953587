import React from 'react';
import HeroBanner from './views/HeroBanner.js';
import LinkContainer from './views/LinkContainer.js';

function App() {

  return (
    <div className="App">
      <header className="App-header">
      </header>
      <body> 
        <HeroBanner />
        <LinkContainer />
      </body>
    </div>
  );
}

export default App;
